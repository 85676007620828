.Button {
  border: none;
  padding: 1rem 2rem;
  border-radius: 5rem;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
}

.Button:active {
  transform: translateY(0.1rem);
  transition: transform 0.01s ease;
}

.Button:hover img {
  opacity: 1!important;
  left: 50%!important;
}

.contactUsButton {
  outline: none;
  border-radius: 4rem;
  font-weight: bold;

  display: flex;

  color: var(--text-color0);
  background: linear-gradient(90deg, rgba(255, 105, 51, 0.2) 0%, rgba(9,9,121,0) 33%, #e86f43 67%, #ff4500 100%);
  background-size: 400%, 400%;
  padding: 0.8rem 1.4rem;
  /* border: 0.14rem solid red; */
  box-shadow: 0 0.2rem transparent;
  text-decoration: none!important;
  transition: background 800ms 1s ease, color 800ms 1s ease;
}

.contactUsButton:hover, .contactUsButton:focus {
  color: white;
  background-position: 97% 0%;
  transition: background 800ms ease, color 500ms ease;
}

/* .contactUsButton:hover::after,
.contactUsButton:focus::after {
	opacity: 0;
} */
