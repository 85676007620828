.cardInput {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  overflow: hidden;
  resize: none;
  border-radius: 0.4rem;
  animation: pulsing_alert 5s infinite;
}

.cardInput:focus {
  animation: none
}

@keyframes pulsing_alert {
  0% {
    background-color: transparent;
  }
  30% {
    background-color: transparent;
  }
  40% {
    box-shadow: 0 0 0 0.1rem #dfdce3;
  }
  61% {
    background-color: transparent;
  }
  100% {
    background-color: transparent;
  }
}