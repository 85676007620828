.NavbarContainer {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 10;
  justify-content: space-between;
  /* background-color: #fff; */
  padding: 0.6rem;
}

.NavbarOnTop {
  padding: 0.8rem;
}

/* nav links - start */
.navItemsWrapper {
  position: relative;
  display: block;
  padding: 10px 0;
}

.navItemsWrapper:active {
  transform: translateY(0.1rem);
  transition: transform 0.01s ease;
}
/* nav links - end */