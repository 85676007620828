.form-policy-terms-container {
  flex-grow: 1;
  flex-basis: 30rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-policy-terms-container button {
  padding: 0.4rem 1rem;
  border: 0;
  /* margin-top: 2rem; */
  border-radius: 0.4rem;
  cursor: pointer;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  opacity: 0.7;
  background-color: white;
}
.form-policy-terms-container button:hover {
  opacity: 1;
}

.form-policy-terms-container span {
  font-size: 1.4rem;
  /* margin: 0 auto;
  margin-bottom: 2rem; */
}

.form-input-policy-terms {
  display: flex;
  flex-direction: column;
  position: relative;
  /* background-color: rgb(34,45,50); */
  padding: 0.2rem;
  border-radius: 0.4rem;
  gap: 1rem;
}

.form-input-policy-terms input {
  margin-bottom: 0.2rem;
}

.form-input-policy-terms input,
.form-input-policy-terms textarea {
  padding: 0.6rem 1rem;
  border: 0;
  font-size: 1.1rem;
  outline: 0;
  background-color: white;
}

.form-input-policy-terms textarea::selection,
.form-input-policy-terms input::selection {
  background-color: white
}

.form-input-policy-terms textarea::-webkit-scrollbar {
  width: 1rem;
  height: 1.125rem;
}

.form-input-policy-terms textarea::-webkit-scrollbar-corner {
  background-color: transparent;
}

.form-input-policy-terms textarea::-webkit-scrollbar-thumb {
  height: 0.375rem;
  background-clip: padding-box;
  -webkit-border-radius: 0.438rem;
  border-radius: 0.5rem;
  background-color: white;
  -webkit-box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
  inset 1px 1px 0px rgb(0 0 0 / 5%);
  box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
  inset 1px 1px 0px rgb(0 0 0 / 5%);
}

